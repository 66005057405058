import React from 'react';
import classes from './SearchBar.module.css';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import LawyerInfo from './../LawyerInfo/LawyerInfo';



export default function SearchBar(props) {
    const [inputFirmName, setInputFirmName] = React.useState("");
    const [inputKeyword, setInputKeyword] = React.useState(props.state.keyword);
    if(inputFirmName !== props.state.firmName && props.state.textAreaFirmName === props.state.firmName){setInputFirmName(props.state.firmName)}
    if(inputKeyword !== props.state.keyword && props.state.textAreaKeyword === props.state.keyword){setInputKeyword(props.state.keyword)}
    const handleClick = () => {
        props.setPageStatus(false);
        props.changeSearchFirmName(inputFirmName, false);
        props.changeSearchKeyword(inputKeyword, false);
        props.addSearch();
        props.setPageStatus(false);
        props.setPage(0);
    }
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            props.setPageStatus(false);
            props.changeSearchFirmName(inputFirmName, false);
            props.changeSearchKeyword(inputKeyword, false);
            props.addSearch();
            props.setPageStatus(false);
            props.setPage(0);
        }
    }
    const handleChangeFirmName = (event) => {
        setInputFirmName(event.target.value);
        props.changeSearchFirmName(event.target.value, false);
    }
    const handleChangeKeyword = (event) => {
        setInputKeyword(event.target.value);
        props.changeSearchKeyword(event.target.value);
    }
    const handleClickOnChooseLocation = () => {
        props.setOpenDialog(true);
    }
    return (
        <div >
            <div className={classes.btmline}><strong>SEARCH BY FIRM NAME / DOMAIN</strong></div>
            <form className={classes.form} noValidate autoComplete="off">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <input className={classes.inp} value={inputFirmName} placeholder="Firm Name / Domain Name" onChange={ handleChangeFirmName } onKeyDown={handleKeyDown}/>
                    </Grid>
                    <Grid item xs={12}>
                        <input className={classes.inp} value={inputKeyword} placeholder="Location" onChange={ handleChangeKeyword }  onKeyDown={handleKeyDown}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button className={ classes.btn } variant="contained"  onClick={ handleClick }>Search</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" className={ classes.model__btn } onClick={ handleClickOnChooseLocation }>Top Law Firms By Location</Button>
                    </Grid>
                </Grid>
                
            </form>
            
            {props.state.searching && props.state.firmName !== "" && <LawyerInfo state={props.state} addSearch={props.addSearch} setPageStatus={props.setPageStatus} firmname={ props.state.firmName } changeSearchKeyword={props.changeSearchKeyword} keyword={ props.state.keyword }/>}
            
        </div>
    );
}