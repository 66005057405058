import React, { Component } from 'react';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from 'react-apollo';
import Leaders from './../Leaders/Leaders';
import classes from './App.module.css';
import SearchBar from './../SearchBar/Searchbar'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Scrollbars } from 'react-custom-scrollbars';
import TablePagination from '@material-ui/core/TablePagination';
import Logo from './Logo'
import StartDialog from './../StartDialog/StartDialog'


const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Product Sans',
      'sans-serif',
    ].join(','),
  },});



const client = new ApolloClient({ 
  uri: 'https://api.hennessey.com/prod/graphql' }, fetch);

export default function App(props) {
  let mobile = false;
  if (window.innerWidth <= 960){
    mobile = true;
  }
  const [openDialog, setOpenDialog] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [pageSetted, setPageStatus] = React.useState(false);
  const [length, setLength] = React.useState(0);
  let responsiveRowsPerPageCount = 25;
  if (window.innerWidth < 960){
    responsiveRowsPerPageCount = 5;
  }
  const [rowsPerPage, setRowsPerPage] = React.useState(responsiveRowsPerPageCount);
  
  const handleChangePage = (event, newPage) => {
      setPage(newPage);
  }

  const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
  }

  
  return (
    <div className={classes.background}>
    <ApolloProvider client={client}>
      
        <ThemeProvider theme={theme}>
          <StartDialog {...props} open={openDialog} setOpen={setOpenDialog} setPage={setPage} setPageStatus={setPageStatus}/>
          <div className={classes.paper}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                  
                  <div className={classes.glass__container} id="searchResults">
                    {mobile && <Logo state={props.state}/>}
                    {mobile && <SearchBar setOpenDialog={setOpenDialog} setPageStatus={setPageStatus} setPage={setPage} state={ props.state } addSearch={ props.addSearch } changeSearchFirmName={ props.changeSearchFirmName } changeSearchKeyword={ props.changeSearchKeyword }/>
                      ||
                      <Scrollbars renderTrackVertical={props => <div {...props} className={classes.track}/>}
                      renderThumbVertical={props => <div {...props} className={classes.thumb}/>} 
                      renderTrackHorizontal={() => <div></div>} 
                      renderThumbHorizontal={() => <div></div>}>

                    
                    <SearchBar setOpenDialog={setOpenDialog} setPageStatus={setPageStatus} setPage={setPage} state={ props.state } addSearch={ props.addSearch } changeSearchFirmName={ props.changeSearchFirmName } changeSearchKeyword={ props.changeSearchKeyword }/>
                    </Scrollbars>
                    }
                    
                    <div className={classes.powered__by}>Powered by <span className={classes.small__red}>Hennessey Digital</span></div>
                  </div>

                </Grid>
                <Grid item xs={12} md={9}>
                  <div className={classes.glass__container__leaders} id="leaderResults">
                    {!mobile && <Logo state={props.state}/>}
                    {mobile && <Leaders pageSetted={pageSetted} setPageStatus={setPageStatus} setPage={setPage} changeFirmPosition={props.changeFirmPosition} state={props.state} page={page} setLength={setLength} rowsPerPage={rowsPerPage} addSearch={ props.addSearch } changeSearchFirmName={ props.changeSearchFirmName } changeSearchKeyword={ props.changeSearchKeyword }/>
                     ||
                    <Scrollbars renderTrackVertical={props => <div {...props} className={classes.track}/>}
                      renderThumbVertical={props => <div {...props} className={classes.thumb}/>} 
                      renderTrackHorizontal={() => <div></div>} 
                      renderThumbHorizontal={() => <div></div>}
                      className={classes.scrolldiv}
                      >
                    <Leaders pageSetted={pageSetted} setPageStatus={setPageStatus} setPage={setPage} changeFirmPosition={props.changeFirmPosition} state={props.state} page={page} setLength={setLength} rowsPerPage={rowsPerPage} addSearch={ props.addSearch } changeSearchFirmName={ props.changeSearchFirmName } changeSearchKeyword={ props.changeSearchKeyword }/>
                    </Scrollbars>
                    }
                    {responsiveRowsPerPageCount === 5 && 
                      <TablePagination
                            rowsPerPageOptions={[]}
                            component="div"
                            count={length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            className={classes.pagination}
                            color="white"
                        />
                     ||
                    <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            className={classes.pagination}
                            color="white"
                        />
                      }
                  </div>
                </Grid>
              </Grid>
          </div>
        </ThemeProvider>
      
    </ApolloProvider>
    </div>
  );
  }
