import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';


function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

let rerenderEntireTree = () => {
    ReactDOM.render(<App changeFirmPosition={changeFirmPosition} state={ state } addSearch = { addSearch } changeSearchFirmName={ changeSearchFirmName } changeSearchKeyword={ changeSearchKeyword }/>,document.getElementById('root'));
}
let state = {
    searching: false,
    firmName: "",
    keyword: "",
    textAreaFirmName: "",
    textAreaKeyword: "",
    firmPosition: 0,
    leadersPage: 0,
    website: false,
}

let addSearch  = (firmname, keyword) => {
    let notTrimmedFirmName = false;
    let notTrimmedKeyword = false;
    state.website = false;
    state.searching = true;
    state.firmPosition = 0;
    if(state.textAreaFirmName !== state.textAreaFirmName.trim()){notTrimmedFirmName = true;}
    if(state.textAreaKeyword !== state.textAreaKeyword.trim()){notTrimmedKeyword = true;}
    state.textAreaFirmName = state.textAreaFirmName.trim();
    state.textAreaKeyword = state.textAreaKeyword.trim();
    if (notTrimmedFirmName || notTrimmedKeyword){rerenderEntireTree();}
    state.firmName = state.textAreaFirmName;
    if (validURL(state.firmName)) {
        state.website = true;
    }
    state.keyword = state.textAreaKeyword;
    rerenderEntireTree();
    let searchBarElmnt = document.getElementById("searchResults");
    searchBarElmnt.scrollIntoView();
}

let changeFirmPosition = (pos) => {
    state.firmPosition = pos;
}


let changeSearchFirmName = (firmname, reRender) => {
    state.textAreaFirmName = firmname;
    if(reRender === true){ rerenderEntireTree(); }
}
    

let changeSearchKeyword = (keyword, reRender) => {
    state.textAreaKeyword = keyword;
    if(reRender === true){ rerenderEntireTree(); }
}

rerenderEntireTree();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
