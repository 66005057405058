import React from 'react';
import classes from './LawyerInfo.module.css';
import { gql } from 'apollo-boost';
import { useQuery } from 'react-apollo';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import LinearProgress from '@material-ui/core/LinearProgress';
import { numberWithCommas }  from './../../functions/functions';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function LawyerInfo(props){
    const GET_LAWYERS = gql `
        query ($firmname: String, $keyword: String, $website: String){
            lawyers(firmName: $firmname, searchKeyword: $keyword, orderBy: "keyword", active: true, searchWebsite: $website) {
                firmName
                semrushValue
                keyword
                website
                firstName
                lastName
                state
                city
                address
                zip
                rankingKeywords
                serpPage
                dateCreated
                semrushValueChange
                rankingKeywordsChange
            }
        }
    `;
    
    let variablesForQuery = {}
    variablesForQuery["keyword"] = props.keyword;
    if (props.state.website === true){
        variablesForQuery["firmname"] = null;
        variablesForQuery["website"] = props.firmname;
    }
    else {
        variablesForQuery["firmname"] = props.firmname;
        variablesForQuery["website"] = null;
    }
    const { loading, error, data } = useQuery(GET_LAWYERS,
        { variables: variablesForQuery } );

    
    function SemrushPaper(props) {
        return <div className={classes.lawyer__infofield}><span className={classes.lawyer__infofield__header}>Semrush Value: </span><strong>${numberWithCommas(props.semrushValue)}</strong>
            {props.semrushValueChange > 0 && <ArrowUpwardIcon style={{ color: "green", position: 'relative', top:2, left: 4, fontSize:14 }} />}
            {props.semrushValueChange < 0 && <ArrowDownwardIcon style={{ color: "red", position: 'relative', top:2, left: 4, fontSize:14 }}/>}
        </div>;
    }

    function RankingKeywordsPaper(props) {
        return <div className={classes.lawyer__infofield}><span className={classes.lawyer__infofield__header}>Ranking keywords: </span><strong>{props.rankingKeywords}</strong>
            {props.rankingKeywordsChange > 0 && <ArrowUpwardIcon style={{ color: "green", position: 'relative', top:2, left: 4, fontSize:14 }} />}
            {props.rankingKeywordsChange < 0 && <ArrowDownwardIcon style={{ color: "red", position: 'relative', top:2, left: 4, fontSize:14 }}/>}
        </div>;
    }
    function handleClickOnKeyword(keyword){
        props.changeSearchKeyword(keyword, false);
        props.setPageStatus(false);
        props.addSearch();
    }
    const [open, setOpen] = React.useState(true);

    const [currentFirm, setCurrentFirm] = React.useState(props.state.firmName);
    const [vertical, setVert] = React.useState("bottom");
    const [horizontal, setHorz] = React.useState("left");

    if (currentFirm !== props.state.firmName){setOpen(true); setCurrentFirm(props.state.firmName);}
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    if (loading) { return <LinearProgress color="secondary" /> };
    if (error) return `Error! ${error}`;
    if (data.lawyers.length === 0){
        return (<div><div className={classes.paper}>
        <div className={classes.btmline}><strong>NO RESULTS</strong></div></div>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal}>
            <Alert severity="error">
                {props.state.firmName} not found!
            </Alert>
        </Snackbar></div>);
    }
    return (
        <div className={classes.paper}>
            <div className={classes.btmline}><strong>RESULTS</strong></div>
            <div style={{ marginTop:10 }}>{props.state.firmPosition !== 0 && <div className={classes.btmline}>
                <div className={classes.lawyer__infofield__position}>Position 
                {props.state.keyword && <span className={classes.small__red}> in {props.state.keyword}</span> || <span className={classes.small__red}> Nationally</span>}: {props.state.firmPosition}</div></div>}
            </div>
            <Slide direction="up" in={!!data} mountOnEnter unmountOnExit>
                <Grid container spacing={0} >
                
                    {data.lawyers.map((row, index) => {
                        if (index === data.lawyers.length - 1){
                            return (
                                <Grid item xs={12} key={index}>
                                    <div>
                                        <SemrushPaper semrushValueChange={row.semrushValueChange} semrushValue={ row.semrushValue }/>
                                        <div className={classes.lawyer__infofield}><span className={classes.lawyer__infofield__header}>Keyword: </span>
                                        <button className={classes.transparentButton} onClick={() => {handleClickOnKeyword(row.keyword);}}>
                                            <strong>{row.keyword}</strong></button>
                                        </div>
                                        <RankingKeywordsPaper rankingKeywordsChange={row.rankingKeywordsChange} rankingKeywords={ row.rankingKeywords }/>
                                        <div className={classes.lawyer__infofield}><span className={classes.lawyer__infofield__header}>Serp page: </span><strong>{row.serpPage}</strong></div>
                                        <div className={classes.lawyer__infofield}><span className={classes.lawyer__infofield__header}>Website: </span>{row.website}</div>
                                        <div className={classes.lawyer__infofield}><span className={classes.lawyer__infofield__header}>Owner: </span>{row.firstName} {row.lastName}</div>
                                        <div className={classes.lawyer__infofield}><span className={classes.lawyer__infofield__header}>Address: </span>{row.address} {row.state} {row.city} {row.zip}</div>
                                    </div>
                                </Grid>
                            );
                        }
                        return (
                        <Grid item xs={12}>
                            <div className={classes.btmline} key={index}>
                                <SemrushPaper semrushValueChange={row.semrushValueChange} rankingKeywordsChange={row.rankingKeywordsChange} semrushValue={ row.semrushValue }/>
                                <div className={classes.lawyer__infofield}><span className={classes.lawyer__infofield__header}>Keyword: </span>
                                    <button className={classes.transparentButton} onClick={() => {handleClickOnKeyword(row.keyword);}}><strong>{row.keyword}</strong></button>
                                    
                                </div>
                                <RankingKeywordsPaper rankingKeywordsChange={row.rankingKeywordsChange} rankingKeywords={ row.rankingKeywords }/>
                                <div className={classes.lawyer__infofield}><span className={classes.lawyer__infofield__header}>Serp page: </span><strong>{row.serpPage}</strong></div>
                                <div className={classes.lawyer__infofield}><span className={classes.lawyer__infofield__header}>Website: </span>{row.website}</div>
                                <div className={classes.lawyer__infofield}><span className={classes.lawyer__infofield__header}>Owner: </span>{row.firstName} {row.lastName}</div>
                                <div className={classes.lawyer__infofield}><span className={classes.lawyer__infofield__header}>Address: </span>{row.address} {row.state} {row.city} {row.zip}</div>
                            </div>
                        </Grid>

                    );})}
                </Grid>
                
            </Slide>
            
        </div>
    );
}