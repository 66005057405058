import React from 'react';
import classes from './App.module.css';
import Logo from './hd-icon.png'
export default function LogoText(props) {
    return (<div>
    {props.state.firmName && 
    <div className={classes.search__for}>
    <img src={Logo} alt="website logo" style={{ marginRight:5 }}/>
    <span className={classes.logo__white}> Legal Digital Marketing Insights: </span>
    <span className={classes.logo__red}>{ props.state.firmName }</span></div> || 
    <div className={classes.search__for}>
    <div className={classes.logo__white}>
    <img src={Logo} alt="website logo" style={{ marginRight:5 }}/>
    <span className={classes.logo__white}> Legal Digital Marketing Insights: </span>
    {props.state.keyword && <span className={classes.logo__red}>Leaders in {props.state.keyword}</span> || 
    <span className={classes.logo__red}>Leaders Nationally</span>}</div></div>}
    </div>);
  }