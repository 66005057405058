import React, { Component } from 'react';
import classes from './StartDialog.module.css';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { gql } from 'apollo-boost';
import { Query } from 'react-apollo';
import LinearProgress from '@material-ui/core/LinearProgress';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
const GET_LOCATIONS_QUERY = gql `
    query {
        locations {
            keyword
        }
    }
`;


export default function StartDialog(props) {
    
    const [scroll, setScroll] = React.useState('paper');
    const handleClose = () => {
        props.setOpen(false);
    };

    const handleListItemClick = (value) => {
        props.changeSearchKeyword(value, false);
        props.addSearch();
        props.setOpen(false);
        props.setPageStatus(false);
        props.setPage(0);
    };
    return(
        
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={props.open} style={{ background: "rgba( 255, 255, 255, 0.10 )", boxShadow: "0 8px 32px 0 rgba(0, 0, 0, 0.37)", backdropFilter: "blur( 5.0px )" }}>
            <DialogTitle id="simple-dialog-title">Choose Location</DialogTitle>
            <DialogContent dividers={scroll === 'paper'}>
                <DialogContentText >
                    <Query query={GET_LOCATIONS_QUERY}>
                        {({ loading, error, data }) => {
                                if (loading){ return <LinearProgress color="secondary" /> }
                                if (error){ return `Error! ${error}` }
                                return(<List>
                                    {data.locations.map((location) => {
                                        if (location["keyword"] === "" || location["keyword"] === " "){
                                            return;
                                        }
                                        location["keyword"] = location["keyword"].replace("Personal Injury Lawyer", "");
                                        location["keyword"] = location["keyword"].replace("Car Accident Lawyer", "");
                                        return(
                                        <ListItem button onClick={() => handleListItemClick(location["keyword"])} key={location["keyword"]}>
                                            <ListItemText primary={location["keyword"]} />
                                        </ListItem>
                                        );
                                    })}
                                </List>);
                            }
                        }
                    </Query>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}